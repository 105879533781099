<template>
	<div class="share-index-page">
		<div class="share-code">
			<p>我的邀请码： {{share_code}}</p>
			<p><span class="copy-btn" @click="copy_invite_code">点击复制</span></p>
		</div>

		<div class="share-qrcode">
			<van-image :src="share_qrcode"></van-image>
		</div>
		<van-row class="share-info">
			<van-col span="12">{{commission_total}} <br /> 累计获得佣金 <p style="font-size:12px;text-align:center;margin-top:5px;color:#F5BC5A;">(30天更新一次)</p></van-col>
			<van-col span="12">{{subordinate_total}} <br /> 成功邀请好友</van-col>
		</van-row>

		<div class="buttons">
			<div class="item" @click="copy_invite_link">
				<span class="icon-link"></span>
				<p>复制链接</p>
			</div>
			<div class="item" @click="save_invite_image">
				<span class="icon-download"></span>
				<p>保存图片</p>
			</div>
		</div>

		<div class="rules" @click="showRulesPopup">活动规则</div>

		<van-popup v-model="rules_show" closeable round  :style="{ width: '280px', height: '380px', padding: '20px' }">
			<h3>邀请规则：</h3>
			<br />
			<p>1、邀请码有什么用？</p>
			<p>邀请码是用户的唯一身份标识，可在邀请好友时使用。</p>
			<br />
			<p>2、如何使用邀请码？</p>
			<p>您的好友通过您的邀请二维码扫码下载“华博证券APP”，或您的好友在APP应用商店下载“华博证券APP”，注册时填写您的邀请码后，方可成为您的好友。（注：同一用户仅可填写一次）</p>
			<br />
			<p>3、参与资格？</p>
			<p>此次邀请活动仅限华博证券代理分销商参与。如果您还不是，立即申请<span @click="saveAgent" style="background-color: red;color:#fff;padding: 0 5px;border-radius: 5px;">代理分销商</span>参与活动，赚取高额佣金。</p>
			<br />
			<p>注：此次邀请活动最终解释权归华博证券所有</p>
		</van-popup>

		<van-popup v-model="warningPopup" closeable round  :style="{ width: '280px', height: '120px', padding: '20px' }">
			<br />
			<p style="text-align:center;font-size:24px;">{{warning_title}}</p>
			<br />
			<p style="text-align:center;">{{warning_content}}</p>
		</van-popup>

	</div>
</template>

<script>
	export default {
		name: 'share_index',
		data() {
			return {
				formItem: {
					token: "",
				},

				share_code: "",
				share_qrcode: "",
				commission_total: "",
				subordinate_total: "",	
				invite_link: "",
				alone_link: "",
				
				rules_show: false,

				warningPopup: false,
				warning_title: "",
				warning_content: "",
			}
		},
		mounted() {
			
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token

				this.init()
			}
		},
		methods: {
			init() {
				this.$axios({
					method: 'post',
					url: 'share/index',
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.share_code = res.data.share_code
						this.share_qrcode = res.data.share_qrcode
						this.invite_link = res.data.invite_link
						this.alone_link = res.data.alone_link
						this.commission_total = res.data.commission_total
						this.subordinate_total = res.data.subordinate_total						
	
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			},

			showRulesPopup() {
				this.rules_show = true
			},

			/*   
             * 复制邀请码
             */
			copy_invite_code(){
                if (window.JsObject != null) {
                    window.JsObject.copyInviteCode(this.share_code);
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"copy_invite_code": this.share_code});
                }
			},

			copy_invite_link(){

                if (window.JsObject != null) {
                    window.JsObject.copyInviteLink(this.invite_link);
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"copy_invite_link": this.invite_link, "alone_link": this.alone_link});
                }
			},

			save_invite_image(){
				this.$axios({
					method: 'post',
					url: 'share/image',
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						console.log(res.data.image)
						
						if (window.JsObject != null) {
							window.JsObject.saveInviteImage(res.data.image);
						}

						if (window.webkit != null) {
							window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"save_invite_image": res.data.image});
						}
					}else{
						return '获取图片失败'
					}
				})
			},

			saveAgent() {
				this.$axios({
					method: 'post',
					url: 'share/distributor',
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.warning_title = "提交成功"
						this.warning_content = "请保持您的联系方式畅通，我们稍后联系您！"

						this.warningPopup = true

					}else{

						this.warning_title = "提交失败"
						this.warning_content = res.error_text

						this.warningPopup = true
					}
				})
			},

			closed() {
				console.log(123)
			},
			
		},
	}
</script>

<style scoped>
.send-code-btn {
	display:inline-block;
	text-align:right;
	float:right;
	color:#FDF773;
	transform: translateY(10px);
}
</style>